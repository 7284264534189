import { Injectable, signal } from '@angular/core';
import { SettingClient } from '@shared/data-access/common';
import { firstValueFrom } from 'rxjs';
import { Monitor } from './webapi';

@Injectable({ providedIn: 'root' })
export class SettingsService {
  static fieldWorkClosedSetting = 'FieldWorkClosed';
  static copyMeasurementYear = 'CopyMeasurementYear';
  static highestReportYear = 'HighestReportYear';
  static fieldWorkClosedSettingText = 'FieldWorkClosedText';
  static isInvitationClosedSettingText = 'IsInvitationClosedTooltip';
  static isInvitationClosedSetting = 'IsInvitationClosed';
  // RegExps for names of Groups in PO and VO: i.e. start with 1 letter followed by character
  static regexpGroupPO = '^(([1-8]{1}[A-Za-z\\s]{0,20})|onderbouw|middenbouw|bovenbouw)$';
  static regexpGroupVO = '^([1-6]{1}[A-Za-z0-9\\s]{0,20})$';
  static regexpGroupBookStart = '^([A-Za-z0-9]{1,1})([A-Za-z0-9\\s]{0,20})$';
  public measurementYear = 0;
  public fieldWorkIsClosed = false;
  public isInvitationClosed = signal<boolean>(false);
  private reportsHighestMeasurementYear = 0;
  private fieldWorkIsClosedIsRetrieved = false;
  private isMeasurementYearRetrieved = false;
  private isReportsHighestMeasurementYearRetrieved = false;
  constructor(private settingsClient: SettingClient) {}

  getRegExpGroupName(monitor: Monitor.Po | Monitor.BookStart | Monitor.Vmbo | Monitor.Mbo | Monitor.Pabo): string {
    switch (monitor) {
      case Monitor.Po:
        return SettingsService.regexpGroupPO;
      case Monitor.Vmbo:
        return SettingsService.regexpGroupVO;
      default:
        return SettingsService.regexpGroupBookStart;
      case Monitor.Mbo:
        return SettingsService.regexpGroupVO; //zelfde als vmbo
      case Monitor.Pabo:
        return SettingsService.regexpGroupVO; //zelfde als vmbo
    }
  }

  async init() {
    if (!this.fieldWorkIsClosedIsRetrieved) {
      this.fieldWorkIsClosed = await this.isFieldWorkIsClosed();
    }
    await this.isInvitationClosed.set((await firstValueFrom(this.settingsClient.getSetting(SettingsService.isInvitationClosedSetting))) === 'true');
    if (!this.isMeasurementYearRetrieved) {
      this.measurementYear = await this.getMeasurementYear();
    }
  }

  async isFieldWorkIsClosed(): Promise<boolean> {
    if (!this.fieldWorkIsClosedIsRetrieved) {
      this.fieldWorkIsClosed = (await firstValueFrom(this.settingsClient.getSetting(SettingsService.fieldWorkClosedSetting))) === 'true';
      this.fieldWorkIsClosedIsRetrieved = true;
    }
    return this.fieldWorkIsClosed;
  }

  async getReportsHighestMeasurementYear(): Promise<number> {
    if (!this.isReportsHighestMeasurementYearRetrieved) {
      this.reportsHighestMeasurementYear = parseInt((await firstValueFrom(this.settingsClient.getSetting(SettingsService.highestReportYear))) + '');
      this.isReportsHighestMeasurementYearRetrieved = true;
    }
    return this.reportsHighestMeasurementYear;
  }

  async getMeasurementYear(): Promise<number> {
    if (!this.isMeasurementYearRetrieved) {
      this.measurementYear = parseInt((await firstValueFrom(this.settingsClient.getSetting(SettingsService.copyMeasurementYear))) + '');
      this.isMeasurementYearRetrieved = true;
    }
    return this.measurementYear;
  }

  async setFieldWorkClosed(closed: boolean) {
    await firstValueFrom(this.settingsClient.setSetting(SettingsService.fieldWorkClosedSetting, closed + ''));
    this.fieldWorkIsClosed = closed;
    this.fieldWorkIsClosedIsRetrieved = false;
  }

  async setCopyMeasurementYear(year: number) {
    await firstValueFrom(this.settingsClient.setSetting(SettingsService.copyMeasurementYear, year + ''));
    this.isMeasurementYearRetrieved = false;
  }

  async setReportsHighestMeasurementYear(year: number) {
    await firstValueFrom(this.settingsClient.setSetting(SettingsService.highestReportYear, year + ''));
    this.isReportsHighestMeasurementYearRetrieved = false;
  }

  async setIsInvitationClosed(closed: boolean) {
    await firstValueFrom(this.settingsClient.setSetting(SettingsService.isInvitationClosedSetting, closed + ''));
    this.isInvitationClosed.set(closed);
  }

  async setSettingText(key: string, text: string) {
    await firstValueFrom(this.settingsClient.setSetting(key, text));
  }

  async getSettingText(key: string): Promise<string | null> {
    return await firstValueFrom(this.settingsClient.getSetting(key));
  }
}
